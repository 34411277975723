.bazis-control {
    /*
    * @prop --margin-start: внешний начальный отступ контрола
    * @prop --margin-end: внешний конечный отступ контрола
    * @prop --margin-top: внешний верхний отступ контрола
    * @prop --margin-bottom: внешний нижний отступ контрола
    *
    * @prop --padding-field-start: внутренний начальный отступ поля
    * @prop --padding-field-end: внутренний конечный отступ поля
    * @prop --padding-field-top: внутренний верхний отступ поля
    * @prop --padding-field-bottom: внутренний нижний отступ поля
    *
    * @prop --background: фон поля
    * @prop --background-hover: фон поля при наведении
    * @prop --background-focus: фон поля при фокусе посредством клавиш
    * @prop --background-active: фон поля при состоянии active
    * @prop --background-invalid: фон поля при состоянии invalid
    * @prop --background-disabled: фон отключенного поля 

    * @prop --border-radius: радиус скругления поля
    * @prop --border-width: толщина границы поля
    * @prop --border-style: стиль границы поля
    * @prop --border-style-hover: стиль границы при наведении
    * @prop --border-style-focus: стиль границы при фокусе посредством клавиш
    * @prop --border-style-active: стиль границы при состоянии active
    * @prop --border-style-invalid: стиль границы при состоянии invalid
    * @prop --border-style-disabled: стиль границы отключенного поля
    * @prop --border-style-estimated: стиль границы расчетного поля (ридонли, но значения образуется из других полей)
    * @prop --border-color: цвет границы поля
    * @prop --border-color-hover: цвет границы поля при при наведении
    * @prop --border-color-focus: цвет границы поля при фокусе посредством клавиш
    * @prop --border-color-active: цвет границы поля при состоянии active
    * @prop --border-color-invalid: цвет границы поля при состоянии invalid
    * @prop --border-color-disabled: цвет границы отключенного поля
    *
    * @prop --control-width: ширина контрола, по умолчанию не задана
    *
    * @prop --color: цвет текста поля 
    * @prop --color-hover: цвет текста поля при наведении
    * @prop --color-focus: цвет текста поля при фокусе посредством клавиш
    * @prop --color-active: цвет текста поля при состоянии active
    * @prop --color-invalid: цвет текста поля при состоянии invalid
    * @prop --color-disabled: цвет текста отключенного поля
    *
    * @prop --height: высота поля
    * @prop --font-size: размер шрифта поля
    * @prop --size-icon: размер иконки
    *
    * @prop --note-font-size: размер шрифта комментария
    * @prop --note-line-height: интерльяж комментария
    * @prop --note-margin: отступ комментария
    * @prop --note-color: цвет комментария
    *
    * @prop --error-font-size: размер шрифта ошибки
    * @prop --error-line-height: интерльяж ошибки
    * @prop --error-margin: отступ ошибки
    * @prop --error-color: цвет ошибки
    *
    * @prop --comment-font-size: размер шрифта коммента
    * @prop --comment-line-height: интерльяж коммента
    * @prop --comment-margin: отступ коммента
    * @prop --comment-color: цвет коммента
    */
    @include props-margin;

    --padding-field-start: 0;
    --padding-field-end: 0;
    --padding-field-top: 0;
    --padding-field-bottom: 0;

    --background-hover: var(--background);
    --background-focus: var(--background);
    --background-active: var(--background);
    --background-disabled: var(--background);
    --border-radius: initial;
    --border-width: initial;
    --border-color: initial;
    --border-color-hover: var(--border-color);
    --border-color-focus: var(--border-color);
    --border-color-active: var(--border-color);
    --border-color-disabled: var(--border-color);
    --border-style: initial;
    --border-style-hover: var(--border-style);
    --border-style-focus: var(--border-style);
    --border-style-active: var(--border-style);
    --border-style-disabled: var(--border-style);
    --border-style-estimated: var(--border-style);
    --color: inherit;
    --color-hover: var(--color);
    --color-focus: var(--color);
    --color-active: var(--color);
    --color-disabled: var(--color);

    --font-size: inherit;
    --size-icon: initial;

    --note-font-size: calc(var(--font-size) - 2px);
    --note-line-height: 12px;
    --note-margin: var(--bazis-margin-3x);
    --note-color: var(--bazis-text-color-secondary);

    --comment-font-size: var(--font-size);
    --comment-line-height: 20px;
    --comment-margin: var(--bazis-margin-4x);
    --comment-color: var(--bazis-text-color);

    position: relative;
    width: var(--control-width);

    @include margin;

    &__field-group {
        position: relative;
        display: flex;
        align-items: center;
        background: var(--background);
        border-radius: var(--border-radius);
        border-width: var(--border-width);
        border-style: var(--border-style);
        border-color: var(--border-color);

        &-wrapper {
            display: flex;
            align-items: center;
            width: 100%;

            bazis-button {
                flex-shrink: 0;
            }
        }

        &:hover {
            background: var(--background-hover);
            border-color: var(--border-color-hover);
            border-style: var(--border-style-hover);

            .bazis-control__field {
                color: var(--color-hover);

                &.bazis-text_placeholder {
                    color: var(--bazis-placeholder-color);
                }
            }
        }
        &:focus,
        &--focused,
        &:focus:hover,
        &--focused:hover {
            background: var(--background-focus);
            border-color: var(--border-color-focus);
            border-style: var(--border-style-focus);

            .bazis-control__field {
                color: var(--color-focus);
            }

            .bazis-control__field-group__end {
                bazis-button.bazis-btn_clear:not(.bazis-eraser) {
                    bazis-icon {
                        color: var(--color-focused);
                    }
                }
            }
        }
        &:active,
        &--activated {
            background: var(--background-active);
            border-color: var(--border-color-active);
            border-style: var(--border-style-active);

            .bazis-control__field {
                color: var(--color-active);
            }

            .bazis-control__field-group__end {
                bazis-button.bazis-btn_clear:not(.bazis-eraser) {
                    bazis-icon {
                        color: var(--color-activated);
                    }
                }
            }
        }
        &:disabled,
        &[disabled],
        &--disabled {
            background: var(--background-disabled);
            border-color: var(--border-color-disabled);
            border-style: var(--border-style-disabled);
            color: var(--color-disabled);
            pointer-events: none;

            input,
            select,
            .bazis-control__field-group__start,
            .bazis-control__field-group__end {
                background: transparent;
            }

            .bazis-select__trigger {
                background: var(--background-disabled);
            }
        }

        &_estimated {
            border-style: var(--border-style-estimated);

            &:hover,
            &:focus,
            &.bazis-control__field-group--focused,
            &:active,
            &.bazis-control__field-group--activated,
            &:disabled,
            &[disabled],
            &.bazis-control__field-group--disabled {
                background: var(--background);
                border-color: var(--border-color);
                border-style: var(--border-style-estimated);
                color: var(--color);
            }
        }

        bazis-icon {
            width: var(--size-icon);
            height: var(--size-icon);
            // margin: 0; // для чего?
            color: var(--bazis-placeholder-color);
        }

        button {
            flex-shrink: 0;
            outline: 0;
            border: 0;
            padding: 0;
            margin: 0;
            background: transparent;
            min-width: var(--height);
            height: var(--height);

            // для кнопок инкримента в числовом поле
            // &:first-of-type {
            //     text-align: right;
            // }
            // &:last-of-type {
            //     text-align: left;
            // }
        }

        bazis-badge.bazis-badge-medium {
            border-radius: 2px;
            padding: 0 6px;
            text-transform: uppercase;
        }

        &__start,
        &__end {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            background: var(--background);
            color: var(--bazis-placeholder-color);
            text-align: center;

            > * {
                flex-shrink: 0;
                min-width: var(--height);
            }

            bazis-interrogation {
                --margin-start: 0;
                display: block;
                text-align: center;
            }

            bazis-button.bazis-btn_clear {
                --padding-end: var(--bazis-padding-3x);
                --padding-start: var(--bazis-padding-3x);

                :hover {
                    bazis-icon {
                        color: var(--color-hover);
                    }
                }
            }
        }

        &__start {
            border-radius: var(--border-radius) 0 0 var(--border-radius);

            + .bazis-control__field,
            + .bazis-control__field .bazis-select,
            + .bazis-control__field-wrapper .bazis-control__field,
            + .bazis-control__field-wrapper .bazis-control__field .bazis-select {
                padding-left: 0;
            }
        }
        &__end {
            border-radius: 0 var(--border-radius) var(--border-radius) 0;

            button {
                border-left: 1px solid var(--bazis-border-color);
            }

            .bazis-eraser {
                visibility: hidden;

                .bazis-control__field-group:hover &:not(.bazis-control_autocomplete &) {
                    visibility: visible;
                }

                .bazis-control_autocomplete.bazis-control--activated &,
                .bazis-control_autocomplete.bazis-control--focused &,
                .bazis-control_autocomplete.bazis-control--dirty & {
                    visibility: visible;
                }
            }
        }
    }

    &__field-wrapper {
        position: relative;
        flex: 1 1 auto;
    }

    &__field {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: var(--height);
        outline: none;
        border: none;
        border-radius: var(--border-radius);
        background: transparent;
        padding-left: var(--padding-field-start);
        padding-right: var(--padding-field-end);
        padding-top: var(--padding-field-top);
        padding-bottom: var(--padding-field-bottom);
        font-size: var(--font-size);
        font-weight: 400;

        &::placeholder {
            color: var(--bazis-placeholder-color);
        }

        &[type='number'] {
            -moz-appearance: textfield;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }

        .bazis-control__field-group_estimated &,
        &[disabled],
        .bazis-control__field-group--disabled & {
            pointer-events: none;
        }

        &.bh-no-padding,
        &.bh-no-padding + .bazis-mask {
            padding-left: 0;
            padding-right: 0;
        }

        &--erasable {
            padding-right: 0;
        }
    }

    &__units {
        display: block;
        border-left: 1px solid var(--bazis-border-color);
        padding-left: var(--bazis-padding-3x);
        padding-right: var(--bazis-padding-3x);
        font-size: var(--font-size);
        font-weight: 500;
        line-height: var(--height);
        white-space: nowrap;
        letter-spacing: 0.3px;
    }

    &__note {
        margin: var(--note-margin) 0 0;
        font-size: var(--note-font-size);
        font-weight: 400;
        line-height: var(--note-line-height);
        color: var(--note-color);

        p {
            --bazis-font-size-p: var(--note-font-size);
            --bazis-line-height-p: var(--note-line-height);
            --bazis-p-margin: var(--note-margin) 0 0;

            &:first-child {
                --bazis-p-margin: 0;
            }
        }

        .bazis-color {
            color: var(--bazis-color-base);
        }
    }

    &__error {
        @extend .bazis-error;
    }

    &__comment {
        margin: var(--comment-margin) 0 0;
        font-size: var(--comment-font-size);
        font-weight: 400;
        line-height: var(--comment-line-height);
        color: var(--comment-color);

        p {
            --bazis-font-size-p: var(--comment-font-size);
            --bazis-line-height-p: var(--comment-line-height);
            --bazis-p-margin: var(--comment-margin) 0 0;

            &:first-child {
                --bazis-p-margin: 0;
            }
        }
    }

    textarea {
        resize: vertical;
    }

    // states
    &--activated {
    }
    &--focused {
    }
    &--dirty {
    }
    &--invalid {
    }
    &--disabled {
    }
}
